import React, { useState } from "react"
import PropTypes from "prop-types"
import { keyframes } from "@emotion/react"
import { lighten } from "@theme-ui/color"

import Box from "./box"

const CardFlip = ({ width, height, front, back }) => {
  const [state, setState] = useState({
    isFlipped: false,
  })

  const toggleFlip = () => {
    setState({
      ...state,
      isFlipped: !state.isFlipped,
    })
  }

  const animateFlip = keyframes({
    "0%": {
      transform: "translate3d(0, 0, 0) rotateY(0)",
    },
    "33%": {
      transform: "translate3d(0, 0, -240px) rotateY(0)",
    },
    "66%": {
      transform: "translate3d(0, 0, -240px) rotateY(180deg)",
    },
    "100%": {
      transform: "translate3d(0, 0, 0) rotateY(180deg)",
    },
  })

  const animateFlipBack = keyframes({
    "0%": {
      transform: "translate3d(0, 0, 0) rotateY(180deg)",
    },
    "33%": {
      transform: "translate3d(0, 0, -240px) rotateY(180deg)",
    },
    "66%": {
      transform: "translate3d(0, 0, -240px) rotateY(0)",
    },
    "100%": {
      transform: "translate3d(0, 0, 0) rotateY(0)",
    },
  })

  return (
    <Box
      as="button"
      bg="transparent"
      display="inline-flex"
      onClick={toggleFlip}
      position="relative"
      cursor="pointer"
      userSelect="none"
      aria-label="umdrehen"
      sx={{
        width: width,
        height: height,
        perspective: "800px",

        "> .flip": {
          animation: `${animateFlip.toString()} ease-out 500ms forwards`,
        },
        "> .flip-back": {
          animation: `${animateFlipBack.toString()} ease-out 500ms forwards`,
        },
        ".card-front, .card-back": {
          bg: "muted",
          borderRadius: "lg",
          overflow: "hidden",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          backfaceVisibility: "hidden",
        },
        ".card-front:hover, .card-back:hover": {
          bg: lighten("muted", 0.1),
        },
        ".card-back": {
          transform: "rotateY(180deg)",
        },
      }}
    >
      <Box
        className={state.isFlipped ? "flip" : "flip-back"}
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          transformStyle: "preserve-3d",
        }}
      >
        <Box className="card-front">{front}</Box>
        <Box className="card-back">{back}</Box>
      </Box>
    </Box>
  )
}

CardFlip.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
}

CardFlip.defaultProps = {
  width: [48, 72, 80, 100, 128],
  height: [48, 72, 80],
}

export default CardFlip
